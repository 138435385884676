import React, { useCallback } from 'react';

import { IProfile, ISession } from '@guider-global/shared-types';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import VideoCameraFront from '@mui/icons-material/VideoCameraFront';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { SiMicrosoftteams } from 'react-icons/si';

// Hooks
import { useBaseLanguage } from '@guider-global/sanity-hooks';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  getFriendlyDateFormat,
  getISOStringWithoutMilliseconds,
  getLanguageFromCMS,
  getSubDomain,
  getTimeFormat,
} from '@guider-global/front-end-utils';
import { defaultPalette, primitiveColors } from '@guider-global/ui';
import { formatInTimeZone } from 'date-fns-tz';
import { useLocalization, useMixpanelEvents } from 'hooks';

export interface IAttendee extends Partial<IProfile> {
  userPicture?: string;
}

interface ISessionsDetails {
  session: ISession;
  attendees: IAttendee[];
  timezone: string;
}

const HAS_ACCEPTED = 0;
const IS_TENTATIVE = 1;
const HAS_DECLINED = 2;
const NO_RESPONSE = 3;
type AttendeeResponse = [
  IAttendee,
  (
    | typeof HAS_ACCEPTED
    | typeof IS_TENTATIVE
    | typeof HAS_DECLINED
    | typeof NO_RESPONSE
  ),
];

export const SessionsDetails: React.FC<ISessionsDetails> = ({
  session,
  attendees,
  timezone,
}) => {
  //base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { trackScreenTime } = useMixpanelEvents();

  const trackSessionJoinButtonClick = useCallback(() => {
    trackScreenTime(
      'Relationship - Session - Session viewed - Join call button',
      { Started: getISOStringWithoutMilliseconds() },
    );
  }, [trackScreenTime]);

  if (!session?.createdAt) {
    return <></>;
  }
  const { getProfileImage } = useProfileImage();
  const locale = getLanguageFromCMS(baseLanguage?.language_code);

  const formattedTime = getTimeFormat(baseLanguage?.time_format);
  const formattedDate = getFriendlyDateFormat(baseLanguage?.date_format);

  const { acceptedEmails, tentativeEmails, declinedEmails } = session;
  const attendeeResponses: AttendeeResponse[] = attendees.map((profile) => {
    const user = session.users.find((user) => user.id === profile.userId);
    const userEmail = user?.email ?? '';
    const hasAccepted = acceptedEmails?.includes(userEmail);
    const isTentative = tentativeEmails?.includes(userEmail);
    const hasDeclined = declinedEmails?.includes(userEmail);

    if (hasAccepted) {
      return [profile, HAS_ACCEPTED];
    }
    if (isTentative) {
      return [profile, IS_TENTATIVE];
    }
    if (hasDeclined) {
      return [profile, HAS_DECLINED];
    }
    return [profile, NO_RESPONSE];
  });
  const sortedAttendeeResponses = attendeeResponses.sort(
    ([, responseA], [, responseB]) => responseA - responseB,
  );

  const renderAttendees = () =>
    sortedAttendeeResponses.map(([profile, response], index) => {
      const hasAccepted = response === HAS_ACCEPTED;
      const isTentative = response === IS_TENTATIVE;
      const hasDeclined = response === HAS_DECLINED;

      let backgroundColor = primitiveColors.black[100];
      if (hasAccepted) {
        backgroundColor = primitiveColors.green[10];
      } else if (isTentative) {
        backgroundColor = primitiveColors.yellow[10];
      } else if (hasDeclined) {
        backgroundColor = primitiveColors.red[10];
      }

      const image = getProfileImage({
        profilePicture: profile.picture,
        userPicture: profile?.userPicture,
      });
      return (
        <Box
          key={profile.id}
          sx={{
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: '10px',
            padding: '4px 8px',
            mt: index !== 0 ? 1.25 : 0,
            backgroundColor,
          }}
        >
          <Avatar
            src={image}
            sx={{ width: '24px', height: '24px' }}
            variant="rounded"
          />

          <Typography variant="body2" sx={{ mx: 1 }}>
            {profile.displayName}
          </Typography>
          {hasAccepted && (
            <CheckCircleIcon
              sx={{ fontSize: '16px', color: defaultPalette.success?.main }}
            />
          )}
          {isTentative && (
            <HelpIcon
              sx={{ fontSize: '16px', color: defaultPalette.warning?.main }}
            />
          )}
          {hasDeclined && (
            <CancelIcon
              sx={{ fontSize: '16px', color: defaultPalette.error?.main }}
            />
          )}
        </Box>
      );
    });

  const renderJoinSessionButton = () => {
    switch (session.videoConferencing) {
      case 'guider':
        return (
          <Button
            data-cy="components_Sessions_SessionsDetails_video-button"
            component="a"
            variant="outlined"
            color="info"
            size="small"
            startIcon={<VideoCameraFront />}
            sx={{ backgroundColor: 'white', fontWeight: 600, mt: 1.5 }}
            fullWidth
            onClick={() => {
              trackSessionJoinButtonClick();
              window.open(
                `${
                  window.location.protocol + '//' + window.location.host
                }/video/${session.id}`,
              );
            }}
          >
            {
              baseLanguage?.relationships?.sessions?.view_session_modal
                ?.join_with_guider_video_button_label
            }
          </Button>
        );
      case 'ms-teams':
        return (
          <Button
            data-cy="components_Sessions_SessionsDetails_video-button"
            component="a"
            variant="outlined"
            color="info"
            size="small"
            startIcon={<SiMicrosoftteams />}
            sx={{ backgroundColor: 'white', fontWeight: 600, mt: 1.5 }}
            fullWidth
            href={session.videoConferencingUrl}
            target="_blank"
            onClick={trackSessionJoinButtonClick}
          >
            {
              baseLanguage?.relationships?.sessions?.view_session_modal
                ?.join_with_ms_teams_button_label
            }
          </Button>
        );
      default:
        return;
    }
  };

  return (
    <>
      <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
        {session.name}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 600, mt: 2 }}>
        {baseLanguage?.relationships?.sessions?.view_session_modal?.when_label}
      </Typography>
      <Typography variant="body2">
        {formatInTimeZone(
          new Date(session.start ?? ''),
          timezone,
          `${formattedDate}`,
          { locale },
        )}
      </Typography>
      <Typography variant="body2" sx={{ mt: 0.75 }}>
        {formatInTimeZone(
          new Date(session.start ?? ''),
          timezone,
          `${formattedTime}`,
          { locale },
        )}
        {' - '}
        {formatInTimeZone(
          new Date(session.end ?? ''),
          timezone,
          ` ${formattedTime}`,
          { locale },
        )}
      </Typography>
      {session.location && (
        <>
          <Typography variant="body1" sx={{ fontWeight: 600, mt: 2 }}>
            {
              baseLanguage?.relationships?.sessions?.view_session_modal
                ?.where_label
            }
          </Typography>
          <Typography variant="body2">{session.location}</Typography>
        </>
      )}
      {renderJoinSessionButton()}
      <Typography variant="body1" sx={{ fontWeight: 600, mt: 2, mb: 1.5 }}>
        {
          baseLanguage?.relationships?.sessions?.view_session_modal
            ?.attendees_label
        }
      </Typography>
      {renderAttendees()}
    </>
  );
};
